<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
    { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
  ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
              <h2 class="brand-text">
              </h2>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }">
      <vertical-nav-menu-items :items="ListMenu" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import axios from 'axios'
import Vue from 'vue';


export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  data() {
    return {
      ListMenu: [],
    }
  },
  mounted() {

  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  created() {
    this.GetMenus()
  },
  methods: {
    async logout() {
      console.log("logout");
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'auth-login' })
    },    
    async GetMenus() {
      try {
        //this.CekToken();
        // axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
        // Vue.prototype.$http = axios;
        const email = localStorage.getItem("email");
        const jwt = localStorage.getItem("token");
        //axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
        const Res = await this.$http.get(`/menuroles?email=${email}`);

        //axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;

        //const Res = await axios.get(`/menuroles?email=${email}`);
        const response = Res;        
        var dataMenu = response.data.data;
        if (dataMenu.length > 0) {
          var TreeMenu = this.MenuTree(dataMenu, 0);
          this.ListMenu = TreeMenu;
          var DataMenu = this.buildMenuTree(dataMenu, 0);
          this.ListMenu = DataMenu;          
        } else {
          this.logout();
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          // Tangani respons "unauthorized" di sini
          console.log("Unauthorized access");
          // Contoh: Tampilkan pesan kepada pengguna bahwa akses tidak diizinkan
        } else {
          // Tangani kesalahan lainnya di sini
          console.error("An error occurred:", error);
        }
        console.log('Masuk Sini');
        console.log(error);
        this.logout();
      }
    },

    MenuTree: function (arr, parent) {
      var out = []
      for (var i in arr) {
        if (arr[i].idhead == parent) {
          var children = this.MenuTree(arr, arr[i].id)
          if (children.length) {
            arr[i].children = children
          }
          out.push(arr[i])
        }
      }
      return out;
    },

    buildMenuTree: function (data, parentId) {
      const menu = [];

      data.forEach(menuItem => {
        if (menuItem.idhead === parentId) {
          const children = this.buildMenuTree(data, menuItem.menuid);
          if (children.length > 0) {
            menuItem.children = children;
          } else {
            menuItem.children = "";
          }
          menu.push(menuItem);
        }
      });

      return menu;
    }


  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
