<template>
  <b-navbar-nav class="nav">
    <b-row>
      <b-col class="content-header-left mb-2" cols="12" md="12">
        <b-card-text class="font-small-3">
          Selamat Datang {{ UserName }}
        </b-card-text>
      </b-col>
    </b-row>
  </b-navbar-nav>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import axios from 'axios';
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'id',
        img: require('@/assets/images/flags/id.png'),
        name: 'Indonesia',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },

  data() {
    return {
      ListUserData: [],
      UserName: ''
    }
  },

  created() {
    //this.AuthMe()
  },
  methods: {
    async AuthMe() {
      try {
        const isLoggedIn = isUserLoggedIn();
        const customUrl = process.env.VUE_APP_BASE_URL;
        const AuthMeURL = customUrl + '/auth/me';

        $.ajax({
          url: AuthMeURL,
          method: 'GET',
          dataType: 'json',
          headers: {
            Authorization: `Bearer ${isLoggedIn}`,
          },
          success: data => {
            console.log('Response data:', data);
          },
          error: error => {
            return next({ name: 'auth-login' });
            console.error ('Error:', error);
          },
        });

      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },

    async fetchData() {
      const customUrl = process.env.VUE_APP_BASE_URL;
      const AuthMeURL = customUrl + '/auth/me';
      axios.get(AuthMeURL, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
        .then(response => {
          // Handle successful response here
          this.ListUserData = response.data.user;
          this.UserName = response.data.user.name;
        })
        .catch(error => {
          if (error.status === 401) {
            // Handle unauthorized response here
            this.handleUnauthorized();
          } else {
            // Handle other error cases here
            this.logout();
            //console.error('An error occurred:', error);
          }
        });
    },
    handleUnauthorized() {
      // Perform actions like redirecting to login page, showing a message, etc.
      // For example:
      this.logout();
    },
    logout() {
      this.LogoutAPI()
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // // Remove userData from localStorage
      // localStorage.removeItem('userData')

      // // Reset ability
      // this.$ability.update(initialAbility)



      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },

    async LogoutAPI() {
      try {
        console.log('Logout Process')
        await this.$http.delete(`/logout`)
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem('userData')
        this.$ability.update(initialAbility)

      } catch (err) {
        console.log(err)
      }
    },



  }
}
</script>

<style></style>
